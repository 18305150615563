@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Oswald:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.fontCust1{
  font-family: 'Dancing Script', cursive;
}
.fontCust2{
    font-family : "Oswald","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fontCust3{
    font-family: 'Quicksand', sans-serif;
}

.fontCust4{
  font-family: 'BenchNine', sans-serif;
}

.fontCust5{
	font-family: 'Pacifico', cursive;
}



.video{
	height: 55%;
  }

@media screen and (max-width: 960px) {
	.video{
		height: 55%;
	  }
}

@media screen and (max-width: 768px) {
	.video{
		height: 33%;
	  }
}

.log{
	height: 34%;
}


.bande{
	background-color : #2b48cc;
  }


  @media screen and (min-width: 768px) {
	.chevron::before {
		border-style: solid;
		border-width: 0.25em 0.25em 0 0;
		content: '';
		display: inline-block;
		top: 50;
		height: 1.5em;
		transform: rotate(-45deg);
		width: 1.5em;
	}
	
	.chevron.right:before {
		left: 0;
		transform: rotate(45deg);
	}
	
	.chevron.bottom:before {
		top: 0;
		transform: rotate(135deg);
	}
	
	.chevron.left:before {
		left: 0.25em;
		transform: rotate(-135deg);
	}
	
	.chevron:hover { display:block; }
}

	



/* Global height of screen, without that the virtual buttons show on top*/
.global_container {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  
}

#floated{
    float: left;
}

.contact{
	background-color: #0a4275;
}


.cred{
	background-color: #4d1616;
}

/*Galaxy Fold */
@media only screen and (max-width: 600px) {
    .gFold{
		width:83.33vw;
    	height:83.33vw;
		background-size: contain;
		flex: none;
	}
	.gFoldFont{
		font-size: 0.75rem; /* 12px */
		line-height: 1rem; /* 16px */
		letter-spacing: 0.025em;
	}
}

.car{
	height: 45%;
}

.aws-btn{
	--slider-transition-duration: 375ms;
--organic-arrow-thickness: 4px;
--organic-arrow-border-radius: 0px;
--organic-arrow-height: 40px;
--organic-arrow-color: #ffffff;
--control-button-width: 10%;
--control-button-height: 25%;
--control-button-background: transparent;
--control-bullet-color: #fafafa;
--control-bullet-active-color: #d5d5d5;
--loader-bar-color: #ffffff;
--loader-bar-height: 0px;
}

.awssld__timer{
	opacity: 0;
    pointer-events: none; /* prevent events (mouse clicking etc.) */
}
.inset-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }


  /* Carousel */

.carousel-container{
	display: flex;
	position: relative;
}

.carousel{
	display: flex;
	height: 100%;
	width: 100%;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
}

.carousel-item{
	display: flex;
	position: relative;
	flex-direction: column;
	flex: 1 0 100%;
	scroll-snap-align: start;
}
.carousel-item img{
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.carousel-item.active p {
	opacity: 1;
	transform: scale(1.1) translateY(-100px);
	transition: all 0.5s ease-in-out;
}


.carousel-item p{
	position: absolute;
	transition: all 0.5s ease-in-out;
	bottom: 0;
	inset: auto 0 0 0;
	text-align: center;
	color: white;
	font-size: 2rem;
}

.carousel-btn{
	height: 30px;
	width: 30px;
	position: absolute;
	transform: rotate(45deg);
	z-index: 2;
	top: 50%;
	cursor: pointer;
	display: none;
}

@media (width > 600px ) {
	.carousel-btn{
		display: inline-block;
	}
	.carousel-item.active p {
		opacity: 1;
		transform: scale(1.1) translateY(-160px);
		transition: all 0.5s ease-in-out;
	}
	.carousel-item p{
		opacity: 0;
		
	}
}
.left-btn{
	border-bottom: 4px solid white;
	border-left: 4px solid white;
	left: 20px;
}


.right-btn{
	border-right: 4px solid white;
	border-top: 4px solid white;
	right: 20px;
}



::-webkit-scrollbar{
	height: 0;
	width: 0;
}

.sizeImage{
	height: 192px;
	width: 322;
}

/* Dropdown */

.drpd{
	position: relative;
}

.drpd-menu{
	position: absolute;
	left: 0;
	top: calc(90%);
	background-color: white;
	margin-left: .75rem;
	border-radius: .75rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
	opacity: 0;
	pointer-events: none;
	transform: translateY(-10px);
	transform: translateX(-15px);
	transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.drpd > .link:hover + .drpd-menu{
	opacity: 1;
	transform: translateY(0);
	transform: translateX(-15px);
	pointer-events: auto;
}

.drpd > .drpd-menu:hover{
	opacity: 1;
	transform: translateY(0);
	transform: translateX(-15px);
	pointer-events: auto;
}

.drpd-menu-item:hover{ 
	background-color: rgb(29 78 216);
	transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
	color : white;
	cursor: pointer;
}


.drpd-menu-b{
	position: absolute;
	left: -150px;
	top: calc(90%);
	background-color: white;
	margin-left: .75rem;
	border-radius: .75rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
	opacity: 0;
	pointer-events: none;
	transform: translateY(-10px);
	transform: translateX(-15px);
	transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.drpd > .link:hover + .drpd-menu-b{
	opacity: 1;
	transform: translateY(0);
	transform: translateX(-15px);
	pointer-events: auto;
}

.drpd > .drpd-menu-b:hover{
	opacity: 1;
	transform: translateY(0);
	transform: translateX(-15px);
	pointer-events: auto;
}

.drpd-menu-item:hover{ 
	background-color: rgb(29 78 216);
	transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
	color : white;
	cursor: pointer;
}


.drpd-menu-n{
	position: absolute;
	left: -110px;
	top: calc(90%);
	background-color: white;
	margin-left: .75rem;
	border-radius: .75rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
	opacity: 0;
	pointer-events: none;
	transform: translateY(-10px);
	transform: translateX(-15px);
	transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
}

.drpd > .link:hover + .drpd-menu-n{
	opacity: 1;
	transform: translateY(0);
	transform: translateX(-15px);
	pointer-events: auto;
}

.drpd > .drpd-menu-n:hover{
	opacity: 1;
	transform: translateY(0);
	transform: translateX(-15px);
	pointer-events: auto;
}

.drpd-menu-item-b:hover{ 
	background-color: rgb(73, 73, 73);
	transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
	color : white;
	cursor: not-allowed;
}
/* .drpd > .link:focus + .drpd-menu{
	opacity: 1;
} */


/* cv submit*/

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    color: white;
    background-color: black;
    display: inline-block;
	margin-top: 10px;
	cursor: pointer;
	transition-duration: 200ms;
}

.inputfile + label:hover {
    background-color: #9b2c2c;
	transition-duration: 200ms;
}



