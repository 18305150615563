::-webkit-scrollbar{
    width: 10px;
}

::-webkit-scrollbar-track{
    
    background-color : #a89797;
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb{
    background-color : #6e0000;
}

@supports (scrollbar-color: red blue) {
    *{
        scrollbar-color: hsl(120 100% 20% / 1) hsl(120 75% 50% / 1);
        scrollbar-width: auto;
    }
}